/* @media (max-width: 1224px) {
  .footer {
      display: none;
  }
}


@media (min-width: 1224px) {
  .bottom-tab-bar {
      display: none;
  }
} */

/* .bottom-tab-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #333;
  color: white;
  padding: 10px 20px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
} */

.bottom-tab-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #1B1B1A;
  color: white;
  padding: 10px 20px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* Ensure it's above other content */
  
  background-size: cover; /* Adjusts the size of the background image */
  background-position: center;
}

.tab-item {
  text-decoration: none;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.tab-item:hover {
  background-color: #555;
}
/* 
.dropdown {
  position: relative;
  display: inline-block;
}

.dropbtn {
  background-color: #333;
  color: white;
  border: none;
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #333;
  min-width: 160px;
  z-index: 99999;
  top: 3rem;
  left: -8rem;
}

.dropdown-content a {
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #555;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #555;
} */

/*  */
.footer {
  height: 100px; /* Custom height */
  background-color: #EFBB4E; /* Example background color */
  color: #fff; /* Example text color */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  color: #ffffff !important;
  padding: 10px 0;
  font-size: 0.7500000025rem;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 300;
}

.social-buttons a {
  color: #EFBB4E;
  margin-right: 10px;
  font-size: 24px;
}

.scroll-to-top {
  cursor: pointer;
  font-size: 24px;
}

.arrow {
  margin-right: 5px; /* Adjust spacing as needed */
}

.nav-item::marker {
  content: "► ";
  font-size: 9px;
  float: left;
  margin-top: 10px;
  margin-left: -17px;
  color: #ffffff;
}

.custom-bullet .nav-item {
  padding: 5px 0;
  list-style: none;
}

.custom-bullet .nav-item {
  display: inline-block;
  margin-right: 15px;
}

.custom-bullet .nav-link {
  text-decoration: none;
  /* Ensure there's no underline */
  color: #ffffff;
  /* Adjust the color if needed */
}

/* In case pseudo-elements are adding the arrow, disable them */
.custom-bullet li::before {
  content: none;
  /* Removes any pseudo-element like arrow or bullet */
}

.scroll-to-top {
  position: fixed;
  bottom: 80px; /* Adjust the distance from the bottom */
  right: 20px; /* Adjust the distance from the right */
  cursor: pointer;
  z-index: 999; /* Ensure it's above other content */
}

.scroll-to-top > svg {
  font-size: 2.5rem; /* Adjust the size of the icon */
  color: #658333; /* Adjust the color of the icon */
}

/* Facebook  */
.social-button-face {
  /* display: inline-block; */
  background-color: white;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.icon1 {
  font-size: 18px;
  color: #007cc2;
  /* Default icon color */
  transition: color 0.3s ease;
  /* Smooth transition for color */
}

/* Hover effect for Facebook button */
.social-button-face.facebook:hover {
  background-color: white;
  /* Facebook blue color on hover */
  transform: scale(1.1);
  /* Slightly scale up the button on hover */
}

.social-button-face.facebook:hover .icon1 {
  color: white;
  /* Keep the icon color white on hover */
}

/* Twitter */
.social-button-twit {
  /* display: inline-block; */
  background-color: white;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.icon2 {
  font-size: 18px;
  color: #007cc2;
  /* Default icon color */
  transition: color 0.3s ease;
  /* Smooth transition for color */
}

/* Hover effect for Facebook button */
.social-button-twit.twitter:hover {
  background-color: white;
  /* Facebook blue color on hover */
  transform: scale(1.1);
  /* Slightly scale up the button on hover */
}

.social-button-twit.twitter:hover .icon2 {
  color: white;
  /* Keep the icon color white on hover */
}

/* Youtube  */
.social-button-you {
  /* display: inline-block; */
  background-color: white;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.icon3 {
  font-size: 18px;
  color: #007cc2;
  /* Default icon color */
  transition: color 0.3s ease;
  /* Smooth transition for color */
}

/* Hover effect for Facebook button */
.social-button-you.youtube:hover {
  background-color: white;
  /* Facebook blue color on hover */
  transform: scale(1.1);
  /* Slightly scale up the button on hover */
}

.social-button-you.youtube:hover .icon3 {
  color: white;
  /* Keep the icon color white on hover */
}

/* Instagram */
.social-button-insta {
  /* display: inline-block; */
  background-color: white;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.icon4 {
  font-size: 18px;
  color: #007cc2;
  /* Default icon color */
  transition: color 0.3s ease;
  /* Smooth transition for color */
}

/* Hover effect for Facebook button */
.social-button-insta.instagram:hover {
  background-color: white;
  /* Facebook blue color on hover */
  transform: scale(1.1);
  /* Slightly scale up the button on hover */
}

.social-button-insta.instagram:hover .icon4 {
  color: white;
  /* Keep the icon color white on hover */
}
